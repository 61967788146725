<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">
          Loans
        </h3>
        <span class="subtitle d-none">
          You have submitted {{ loansThisMonth }} loans this month
        </span>
      </div>
      <div class="page-header-buttons">
        <button type="button" class="btn bg-green" @click="modals.newOrigination = true">
          New Origination
        </button>
      </div>
    </div>
    <div class="page-tabs d-flex justify-content-between">
      <ul class="tab-list">
        <li :class="{ 'active': page === 'loans' }">
          <router-link :to="{ name: 'loans' }">
            Pipeline
          </router-link>
        </li>
        <li :class="{ 'active': page === 'loan_closing' }">
          <router-link :to="{ name: 'loan_closing' }">
            Closing
          </router-link>
        </li>
      </ul>
    </div>
    <div class="page-content p-normal">
      <div class="row mt-2">
        <div class="col-12 col-md-6">
          <div class="search-wrapper">
            <img src="@/assets/icons/icon-search.svg" alt="Icon search">
            <input type="text" placeholder="Search" v-model="searchField">
          </div>
        </div>
        <div class="col-12 col-md-6"></div>
      </div>
      <div class="text-center mt-4" v-if="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="table" v-if="ui.loading === false">
        <table class="table closing-table">
          <thead>
          <th>
            Borrower
          </th>
          <th>Signing date</th>
          <th>Disbursment</th>
          <th>Wire review</th>
          <th>Wire approved</th>
          <th>Signed Docs<br>
            Received by Fuel</th>
          <th>Authorized<br>
            to disburse</th>
          <th>
            Collateral<br>
            uploaded
          </th>
          <th>
            Purchase
          </th>
          </thead>
          <tbody>
            <tr>
            <td>
              Doe, John
            </td>
            <td>
              Jul, 06 2020
            </td>
            <td>
              Jul, 10 2020
            </td>
            <td>
              <img src="@/assets/icons/icon-done.svg" alt="">
            </td>
            <td>
              <img src="@/assets/icons/icon-done.svg" alt="">
            </td>
            <td>
              <img src="@/assets/icons/icon-warning.svg" alt="">
            </td>
            <td>
              <img src="@/assets/icons/icon-done.svg" alt="">
            </td>
            <td>
              <img src="@/assets/icons/icon-done.svg" alt="">
            </td>
            <td>
              <img src="@/assets/icons/icon-pending.svg" alt="">
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <new-origination-modal
        @close="modals.newOrigination = false"
        @loanCreated="modals.newOrigination = false; ui.LoansTable.listing.isLoading = true; loadLoans();"
        v-if="modals.newOrigination"></new-origination-modal>
    </transition>
  </div>
</template>

<script>
import NewOriginationModal from "./NewOriginationModal";

export default {
  name: 'LoansClosing',
  components: { NewOriginationModal },
  data() {
    return {
      loansThisMonth: 0,
      searchField: '',
      modals: {
        newOrigination: false
      },
      ui: {
        loading: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;

      setTimeout(() => {
        this.ui.loading = false;
      }, 1000)
    }
  },
  beforeMount() {
    this.getData()
  },
  computed: {
    page() {
      return this.$route.name
    }
  },
}
</script>

<style lang="scss" scoped>
table {
  tbody {
    td {
      vertical-align: middle;
    }
  }
  &.closing-table {
    thead {
      th {
        vertical-align: middle;
        &:first-child {
          width: 15%;
        }
        &:nth-child(n + 2):nth-child(-n+3) {
          width: 13%;
        }
        &:nth-child(n + 3) {
          width: 10%;
          text-align: center;
        }
      }
    }
    tbody {
      tr {
        td {
          height: 60px;
          vertical-align: middle;
          &:first-child {
            width: 15%;
            border-bottom: 1px solid #E7E8E8;
          }
          &:nth-child(n + 2):nth-child(-n+3) {
            width: 13%;
            border-bottom: 1px solid #E7E8E8;
          }
          &:nth-child(3) {
            border-right: 1px solid #E7E8E8;
            border-bottom: 1px solid #E7E8E8;
          }
          &:nth-child(n + 3) {
            width: 10%;
            text-align: center;
            border-right: 1px solid #E7E8E8;
            border-bottom: 1px solid #E7E8E8;
          }
          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }
}

</style>
